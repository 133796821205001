import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import logo from '../../assets/img/Triangle.svg';
import fileup from '../../assets/img/fileup.png';
import { createMessage } from '../../services/message';
import { fileUpload } from '../../services/file';
import './styles.scss';

export default function UserDetails({ user, order }) {
  const orderChat = order?.orderChat;
  const Messages = orderChat?.Messages;
  const [newMessageContent, setNewMessageContent] = useState('');
  const [messageCreated, setMessageCreated] = useState(null);
  const [uploadedFilePath, setUploadedFilePath] = useState(null);
  useEffect(() => {
    if (messageCreated) {
      Messages?.push(messageCreated);
      setMessageCreated(null);
    }
  }, [messageCreated]);

  const newMessage = () => {
    let messageContent = newMessageContent;
    if (uploadedFilePath) {
      const fileUrl = `${process.env.REACT_APP_API_URL}${uploadedFilePath}`;
      messageContent += `<br /><img src="${fileUrl}" alt="Attached Image" style="width:300px; height:auto" />`;
    }

    const newMsg = {
      content: messageContent,
    };
    createMessage(newMsg, orderChat?.id)
      .then((msgCreated) => {
        setNewMessageContent('');
        setUploadedFilePath(null);
        setMessageCreated(msgCreated);
        toast.success('Your message has been sent successfully, the user will be notified by email');
      })
      .catch((error) => {
        console.error('Error sending message:', error);
        toast.error('Error sending message');
      });
  };

  const handleFileChange = (e) => {
    const formData = new FormData();
    const file = e.target.files[0];
    formData.append('file', file);
    fileUpload(formData)
      .then((response) => {
        setUploadedFilePath(response.path);
      })
      .catch(() => {
        toast.error('Error uploading file');
      });
    const reader = new FileReader();
    reader.readAsDataURL(file);
  };

  const handleFileUploadButtonClick = () => {
    const fileInput = document.getElementById('fileInput');
    fileInput.click();
  };

  return (
    <div className="files-content-cols">
      <div className="user-details back-content">
        <h3 className="subtitle">Client</h3>
        <div className="box-form form-delimitated">
          <div className="box-personal-info">
            <div>
              <span className="subtitle">ZipCode: </span>
              {order && (order.zipCode || ' - ')}
            </div>
            <div>
              <span className="subtitle">Email: </span>
              {user && user.email}
              {order && (order.email || ' - ')}
            </div>
            <div>
              <span className="subtitle">Cupon price: </span>
              {order && !Number.isNaN(parseFloat(order.cuponPrice))
                ? `$${parseFloat(order.cuponPrice).toFixed(2)}`
                : ' - '}
            </div>

          </div>
          <div className="box-address-info">
            <div>
              <span className="subtitle">Address: </span>
              {order && (order.address || ' - ')}
              {user && ' - '}
            </div>
            <div>
              <span className="subtitle">Shipping price: </span>
              {order && `$${order.shippingPrice || ' - '}`}
            </div>
          </div>

        </div>
      </div>
      {order ? (
        <div className="comment-side">
          <div className="comment-box">
            <h2>Comments</h2>
            {Messages.length > 0 ? Messages.map((message) => (
              <div key={message.id}>
                <p>
                  <b>{message.fromAdmin ? 'Admin' : 'User'}</b>
                  {' '}
                  <span>
                    {' '}
                    <Moment format="DD-MM-YYYY - HH:mm">
                      {message.createdAt}
                    </Moment>
                  </span>
                </p>
                <div dangerouslySetInnerHTML={{ __html: message.content }} />
              </div>
            )) : null}
          </div>
          <div className="new-message">
            <input
              className="msg"
              type="text"
              placeholder="Type a message"
              value={newMessageContent}
              onChange={(e) => setNewMessageContent(e.target.value)}
            />
            <button
              className="button-triangle"
              type="button"
              onClick={handleFileUploadButtonClick}
            >
              <img className="fileup" src={fileup} alt="Upload File" />
            </button>
            <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} />
            <button className="button-triangle" type="button" onClick={newMessage}>
              <img src={logo} alt="Send Message" />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
