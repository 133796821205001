import React, { useEffect, useState } from 'react';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Loader from '../../components/loader';
import Pagination from '../../components/pagination';
import { getItemLibraryProducts, getCategories } from '../../services/product';
import './styles.scss';

export default function ListProducts() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState([]);
  const [error, setError] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(20);

  useEffect(() => {
    setLoading(true);
    setError(false);
    getCategories()
      .then((data) => {
        setCategories(data);
      });
    getItemLibraryProducts()
      .then((data) => {
        setProducts(data);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [localStorage.getItem('token')]);
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = products.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  function renderTable() {
    return currentPosts.map((product) => {
      return (
        <tr key={product.id}>
          <td>{product.id}</td>
          <td>{product.name}</td>
          <td>
            $
            {(parseFloat(product.price) / 100).toFixed(2)}
          </td>

          <td>
            <p data-tip data-for={`tooltip${product.id}`}>
              {product.Tags[0].name}
              ...
              {' '}
              {product.Tags.length === 1 ? (null) : (`+${product.Tags.length - 1}`) }
            </p>

            <ReactTooltip id={`tooltip${product.id}`}>
              {product.Tags.map((or) => {
                return (
                  <h4>{or.name}</h4>
                );
              })}
            </ReactTooltip>
          </td>
          <td>{moment(product.createdAt).format('DD/MM/YYYY')}</td>
          <td>
            <Link
              to={{
                pathname: '/edit-products',
                state: {
                  product,
                },
              }}
            >
              Edit Products
            </Link>
          </td>
        </tr>
      );
    });
  }

  const cattt = () => {
    return (
      <select placeholder="Filter by state">
        <option value="" className="optionColor">All</option>
        {categories.map((category) => (
          <option value={category.id} className="optionColor">{category.name}</option>
        ))}
      </select>
    );
  };

  const table = <tbody>{renderTable()}</tbody>;
  const cat = <div>{cattt()}</div>;
  const emptyProducts = (!products || products.length === 0) && <h3 className="empty-orders">No products were found</h3>;
  const filterSearchSelect = (
    <>
      <div className="filters-container">
        <div className="scrolleable">
          <div className="filterContainer">
            <div className="inputFilter">
              <h2>Filter</h2>
              {cat}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  let body = (
    <>
      <div className="user-details back-content">
        <h3 className="subtitle">Products</h3>
        <div className="box-form form-delimitated">
          <div className="box-personal-info inputFilter">
            {filterSearchSelect}
          </div>
        </div>
        <div className="table-container no-margin">
          <div className="scrolleable center-table-items">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Categories</th>
                  <th>Date</th>
                  <th>Edit product</th>
                </tr>
              </thead>
              {table}
            </table>
            {emptyProducts}
          </div>
        </div>
        <div>
          <Pagination
            postsPerPage={postPerPage}
            totalPosts={products.length}
            paginate={paginate}
            current={1}
          />
        </div>
      </div>
    </>
  );

  if (loading) {
    body = (
      <>
        <Loader />
      </>
    );
  }

  if (error) {
    body = (
      <>
        <h3>Error</h3>
      </>
    );
  }

  return (
    <div className="home-page">
      <div className="content-gral">
        <h2 className="title-page">Product List</h2>
        {body}
      </div>
    </div>
  );
}
